div.add-location-form {
    display : flex;
    flex-direction : row
}
div.add-location-form {
    padding : 10px;
}
div.add-location-form div.button button{
    background-color : var(--yellow);
}
div.add-location-form div.button.close button{
    background-color : var(--red);
}