:root {
  --red : #fe4a49;
  --blue : #2ab7ca;
  --green : #14A76C;
  --yellow : #fed766;
  --charcoal : #36454f;
  --medium-dark-grey : #333333;
  --dark-grey : #121212;
  --light-grey : #dddddd;
  --grey : #555555;
}
html {
  font-size: 100%;
}
body {
  width : 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--dark-grey);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
input {
  font-size : 1.25rem;
  background-color: var(--charcoal);
  color : var(--light-grey);
}
textarea {
  font-size : 1.25rem;
  font-family : inherit;
  width : 90%;
  height: 500px;
  background-color: var(--charcoal);
  color : var(--light-grey);
}
.hidden {
  display : none;
}
.app {
  position: relative;
  width : 100%;
}
div.custom-code-editor {
  margin-top : 10px;
  margin-bottom: 10px;
}
div.button {
  display : grid;
  place-items: center;
  margin-top : 10px;
  margin-bottom : 10px;
}
div.input {
  margin-top : 10px;
  margin-bottom : 10px;
  width : 100%;
  display : grid;
  place-items : center;
}
div.input input {
  width : 80%;
}
div.button button {
  font-size : 1.25rem;
  padding : 10px;
  border-radius : 5px;
  border : none;
}
div.login-app {
  position : absolute;
  top : 100px;
  width : 100%;
  height : calc(100vh - 100px);
  display : grid;
  place-items : center;
  background-color: var(--medium-dark-grey);
}
div.form {
  background-color: var(--dark-grey);
  padding : 25px;
  border-radius: 10px;
  text-align: center;
}
div.login.form div.button.login button {
  background-color : var(--yellow);
}
div.login.form div.forgot-password a{
  color : var(--light-grey);
  text-decoration: underline;
}
div.login.form div.forgot-password a:link{
  color : var(--light-grey);
  text-decoration: underline;
}
div.login.form div.forgot-password a:active{
  color : var(--blue);
  text-decoration: underline;
}
div.login.form div.forgot-password a:visited{
  color : var(--light-grey);
  text-decoration: underline;
}
div.login.form div.forgot-password a:hover{
  color : var(--yellow);
  text-decoration: underline;
}
div.login.form div.forgot-password {
  margin : 10px 0 10px 0;
}
nav.navigator-bar {
  position: fixed;
  height: 100px;
  width: 100%;
  background-color: var(--blue);
}
nav.navigator-bar.user {
}
nav.navigator-bar div.title {
  font-size : 2rem;
  color : var(--dark-grey);
  font-weight: bold;
}
nav.navigator-bar.user div.title {
  float : left;
}
nav.navigator-bar.user div.button {
  float : right;
}
nav.navigator-bar div.button button {
  background-color : var(--red);
}
div.forgot-password-app {
  position : absolute;
  top : 100px;
  width : 100%;
  height : calc(100vh - 100px);
  display : grid;
  place-items : center;
  background-color: var(--medium-dark-grey);
}
div.reset-password-app {
  position : absolute;
  top : 100px;
  width : 100%;
  height : calc(100vh - 100px);
  display : grid;
  place-items : center;
  background-color: var(--medium-dark-grey);
}
div.error-message {
  text-align: center;
  color : var(--red);
  
}
div.reset-password-app div.reset-password.form.error div.input input {
  border-bottom : 2px solid var(--red);

}
div.authenticated-app section {
  width : 90%;
  height : 100%;
}