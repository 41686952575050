div.authenticated-app section {
    width : 90%;
    height : 100%;
  }
  div.authenticated-app {
    position: absolute;
    padding : 10px 0 10px 0;
    top : 100px;
    width : 100%;
    clear : both;
    display : grid;
    background-color: var(--dark-grey);
    grid-template-columns: 1fr 9fr;
    grid-template-rows : 1fr 9fr;
    grid-gap : 10px;
  }
  div.authenticated-app div.location-selection-panel {
    grid-row-start : 1;
    grid-row-end : 2;
    grid-column-start : 1;
    grid-column-end : 3;
    justify-self : start;
  }
  div.authenticated-app div.section-selection-panel {
    grid-row-start : 2;
    grid-row-end : 3;
    grid-column-start : 1;
    grid-column-end : 2;
    justify-self : start;
  }
  div.authenticated-app section {
    grid-row-start : 2;
    grid-row-end : 3;
    grid-column-start : 2;
    grid-column-end : 3;
    justify-self : center;
  }