div.custom-code-editor-panel{
    display : grid;
    justify-items : stretch;

    resize : both;
    border: 1px solid black; 
    overflow:auto;
    max-width: 100vw;
}
div.custom-code-editor-panel.menu-open {
    display : flex;
    flex-direction : column;
    overflow : hidden;
    max-height : 50vh;
}
div.custom-code-editor-panel.menu-open div.script-menu {
    overflow-y : auto;
    background-color : var(--charcoal);
    color : white;
}
div.custom-code-editor-panel.menu-open div.script-menu div.script-menu-item{
    overflow-y : auto;
    background-color : var(--charcoal);
    border-top : white 1px solid;
    color : white;
    font-size : 1.25rem;
}
div.custom-code-editor-panel.menu-open div.script-menu div.script-menu-item:hover{
    color : black;
}
div.custom-code-editor-panel.menu-open div.script-menu div.script-menu-item:first-of-type{
    border-top : none;
}
div.custom-code-editor-panel div.button button {
    background-color : var(--yellow);
}
div.custom-code-editor-panel.closed {
    display : grid;
    place-items : center;
    border-radius : 5px;
    border : 1px solid white;
}
div.custom-code-editor-panel.script-open{
    background-color : var(--medium-dark-grey);

}
div.custom-code-editor-panel.script-open.maximized {
    grid-column-start : 1;
    grid-column-end : 3;
}
div.custom-code-editor-panel header {
    display : flex;
    flex-direction : row;
    justify-content: space-around;
}
div.custom-code-editor-panel header div.title{
    display : grid;
    place-content : center;
    font-size : 2rem;
    color : white;
    word-wrap : break-word;
}
div.custom-code-editor-panel header div.controller {
    display : flex;
    flex-direction : row;
    justify-content : flex-end;
    gap : 5px;
}
div.custom-code-editor-panel div.controller div.button.close button {
    background-color : var(--red);
    color : white;
    width : 50px;
}
div.custom-code-editor-panel div.controller div.button.change-size button {
    background-color : var(--green);
    color : white;
    width : 50px;
}
div.custom-code-editor-panel footer {
    display : flex;
    flex-direction : row;
    justify-content : space-around;
}
div.custom-code-editor-panel footer div.comment-box {
    place-items : center;
    font-size : 1.5rem;
    color : white;
}