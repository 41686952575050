.dropdown:hover .content {
    display : block;
}
.dropdown .content {
    display : none;
}
@media only screen and (min-width : 600px) {
    div.dropdown div.content {
        overflow: scroll;
        max-height: 300px;
    }
}
.dropdown .content {
    position : absolute;
    background-color : var(--light-grey);
    width : 100%;
    z-index : 1;
}
.dropdown .content .item {
    border-top : 1px solid var(--dark-grey);
    padding : 16px;
}
.dropdown .content .item:hover {
    font-weight : bold;
}
.dropdown div.selected {
    background : linear-gradient(90deg, var(--orange), var(--purple));
    padding : 16px;
    width : calc(100% - 32px);
    border-radius : 5px 5px 5px 5px;
}
.dropdown:hover div.selected {
    border-radius : 5px 5px 0 0;
}
div.selected input {
    width : calc(100% - 16px);
    display : block;
    margin : 0 auto 0 auto;
    background : none;
    border : none;
    border-bottom : 1px solid black;
}
.dropdown {
    position : relative;
    display : inline-block;
}