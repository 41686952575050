div.location-selection-panel {
    display : flex;
    align-items : center;
    flex-direction : row;
    gap : 10px;
}
div.authenticated-app div.location-selection-panel {
    grid-column-start : 1;
    grid-column-end : 2;
    width : fit-content;
  }
div.location-selection-panel div.location-selector {
    background-color : var(--charcoal);
    color : var(--light-grey);
    padding : 10px;
}
div.location-selection-panel div.location-selector.selected {
    background-color : var(--light-grey);
    color : var(--dark-grey);
}
div.location-selection-panel div.button button{
    background-color : var(--yellow);
}